<script>
/***
 Name: tpl
 Code: sa0ChunLuyu
 Time: 2021/10/15 23:49
 Remark: 模板页面
 */
export default {
  data() {
    return {}
  },
  mounted() {
  },
  methods: {}
}
</script>
<template>
  <div>
    tpl
  </div>
</template>
<style scoped>

</style>